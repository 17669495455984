import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import * as firebase from 'firebase';
import {GeneralService} from '../services/general.service';
import {AuthService} from '../services/auth.service';
import {MoncompteService} from '../services/moncompte.service';
import {Adresses} from '../modeles/adresse.modele';
import {Subject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {Panier} from '../modeles/panier.modele';
import {MailService} from '../services/mail.service';
import {PanierComponent} from '../panier/panier.component';

@Component({
  selector: 'app-livraison',
  templateUrl: './livraison.component.html',
  styleUrls: ['./livraison.component.css']
})
export class LivraisonComponent implements OnInit {
  uid: string;
  email: string;
  date: string;
  retour: string;
  lesAdresses: any;
  panier: Panier[] = JSON.parse(localStorage.getItem('panier')) || [];

  adresses: Adresses[];
  adressesSubscription: Subscription;

  constructor(public generalService: GeneralService,
              public authService: AuthService,
              public moncompteService: MoncompteService,
              private router: Router,
              private mailService: MailService
  ) {
  }

  ngOnInit(): void {
    firebase.auth().onAuthStateChanged(
      (user) => {
        if(user) {
          this.uid = user.uid;
          this.email = user.email;
        }
      }
    );

    this.adressesSubscription = this.moncompteService.adressesSubject.subscribe(
      (adresses: Adresses[]) => {
        this.adresses = adresses;
      }
    );
    this.moncompteService.emitAdresses();
  }

  onSubmit(f: NgForm) {
    const laDate = new Date().toISOString();

    firebase.database().ref('/clients/' + f.value.uid + '/adresses' ).set([{
      date: laDate,
      nom: f.value.nom,
      adresse: f.value.adresse,
      cp: f.value.cp,
      ville: f.value.ville
      }]);
    firebase.database().ref('/commandes'  ).push({
      date: laDate,
      uid: f.value.uid,
      panier: f.value.panier,
      livraison: f.value.livraison,
      statut: 'attente'
    });

    this.mailService.envoiMail('https://dauphinedentaire.fr/assets/envoiMailAudition42.php', f.value).subscribe(
      (data) => {
        console.log('nous sommes ici !' + data);
      },
      err => {
        console.log(err);
      }, () => {
        console.log('la fin');
      }
    );
    localStorage.removeItem('panier');
    localStorage.setItem('date', JSON.stringify(laDate));
    this.panier = [];
    localStorage.setItem('commandePanier', JSON.stringify(f.value.panier));
    this.router.navigateByUrl('/paiement');
  }

}
