import {Injectable} from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class PaiementService {
  public totalCommande(commande) {
    let total = 0;
    for (let data of commande) {
      total += data.quantite * data.prix;
    }
    if (localStorage.getItem('fdp') === 'livraison' && total < 40) {
      total += 5.90;
    }
    return this.formatPrice(total);
  }

  formatPrice(value) {
    let val = ( value / 1 );
    return val.toFixed(2);
  }
  getCommandeId(date) {
    var ref = firebase.database().ref('/commandes');
    ref.orderByChild('/date').equalTo(date).once('child_added', function(snapshot) {
      console.log(snapshot.key);
      return snapshot.key;
    });
  }

  majCommande(statut, date) {
    const ref = firebase.database().ref('/commandes');
    ref.orderByChild('/date').equalTo(date).once('child_added', function(snapshot) {
      console.log(snapshot.key);
      firebase.database().ref('/commandes/' + snapshot.key + '/statut').set(statut);
    });
  }
}
