import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import {Commandes} from '../modeles/commande.modele';
import {Subscription} from 'rxjs';
import {Adresses} from '../modeles/adresse.modele';
import {MoncompteService} from '../services/moncompte.service';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.css']
})
export class CommandesComponent implements OnInit {
  uneCommande: any;
  commandesAdmin: Commandes[];
  commandesAdminSubscription: Subscription;
  adresses: Adresses[];
  adressesSubscription: Subscription;
  uid: string = firebase.auth().currentUser.uid;
  email: string = firebase.auth().currentUser.email;
  commandeDate: string;
  detailCommande: any;


  constructor(public moncompteService: MoncompteService) { }

  ngOnInit(): void {
    this.commandesAdminSubscription = this.moncompteService.commandesAdminSubject.subscribe(
      (commandes: Commandes[]) => {
        this.commandesAdmin = commandes;
      }
    );
    this.moncompteService.emitCommandesAdmin();
  }

}
