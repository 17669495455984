<div class="d-flex justify-content-center" *ngIf="uid === 'FFTj8FBH6OTwGvSr1sYgSQ55E1H3'">
  <div class="col-12" *ngIf="uid && isBoutiqueExiste()">
    <div class="row">
      <div class="col-12">
        <a (click)="onCreerProduit()" *ngIf="unProduit" class="btn btn-success btn-icon-split btn-sm text-white mb-2">
            <span class="icon text-white-50">
              <i class="fas fa-plus"></i>
            </span>
          <span class="text">Créer un produit</span>
        </a>
      </div>

      <div class="col-lg-6 col-12">
        <div class="row border mb-1 p-2" *ngFor="let liste of produits;">
          <div class="col">
            <i class="fas fa-trash text-danger" (click)="this.produitsService.supprimerProduit(liste)"></i>
<!--            <i class="far fa-edit ml-2 text-info mr-3" (click)="this.produitsService.modifierProduit(liste)"></i>-->
            <i class="far fa-edit ml-2 text-info mr-3" (click)="onModifierProduit(liste)"></i>
          </div>
          <div class="col-6">
            <strong>{{ liste.nom }}</strong> • {{ liste.categorie }}
          </div>
          <div class="col">
            <span class="float-right">{{ liste.prix }}€</span>
          </div>
          <div class="col-2">
            <img *ngIf="liste.image" [src]="liste.image" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div *ngIf="this.unProduit">
          <app-produit-modifier></app-produit-modifier>
        </div>

        <div *ngIf="!this.unProduit">
          <app-produit-creer></app-produit-creer>
        </div>
      </div>
    </div>
  </div>


  <div class="col-lg-6 col-12" *ngIf="uid && !isBoutiqueExiste()">
    <div class="card shadow">
      <div class="card-body">
        <p>Bienvenue <strong>{{ uid }}</strong></p>
        <h4>Créer votre boutique</h4>

        <form (ngSubmit)="onCreerBoutique(f)" #f="ngForm">
          <div class="form-group border-bottom pb-1">
            <label for="nom">Nom de la boutique</label>
            <input type="text" class="form-control" id="nom" name="nom" ngModel minlength="3" required>
            <p class="text-info mt-2"><i class="fas fa-info-circle"></i> Etat du nom de la boutique</p>
          </div>
          <div class="form-group">
            <label for="adresse">Adresse de livraison<br><small>lieu où les personnes vont récupérer les commandes</small></label>
            <input type="text" class="form-control" id="adresse" name="adresse" ngModel>
          </div>
          <div class="form-inline">
            <label class="sr-only" for="cp">Code postal</label>
            <input type="text" class="form-control mb-2 mr-sm-2 col-4" name="cp" id="cp" placeholder="Code postal" ngModel>

            <label class="sr-only" for="ville">Ville</label>
            <div class="input-group mb-2 mr-sm-2 col-7">
              <input type="text" class="form-control col" name="ville" id="ville" placeholder="Ville" ngModel>
            </div>
          </div>
          <button type="submit" class="btn btn-primary w-100 mt-4" [disabled]="f.invalid">Créer votre boutique</button>
        </form>
      </div>
    </div>
  </div>
</div>
