import { Component, OnInit } from '@angular/core';
import {GeneralService} from '../services/general.service';
import {AuthService} from '../services/auth.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  title = 'Sans contact, audition42';
  email: string;

  isAuth: boolean;

  constructor(public generalService: GeneralService, private authService: AuthService) { }

  ngOnInit() {
    firebase.auth().onAuthStateChanged(
      (user) => {
        if(user) {
          this.isAuth = true;
          this.email = firebase.auth().currentUser.email;
        } else {
          this.isAuth = false;
        }
      }
    );
  }

  onSignOut() {
    this.authService.signOutUser();
    localStorage.removeItem('user');
  }
}
