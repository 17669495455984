import { Component } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'achatSansContact';

  constructor() {
    // Your web app's Firebase configuration
    var firebaseConfig = {
      apiKey: "AIzaSyChVE3GYfPlURbYBHuuHdmccAy4LsPLdes",
      authDomain: "achatsanscontact.firebaseapp.com",
      databaseURL: "https://achatsanscontact.firebaseio.com",
      projectId: "achatsanscontact",
      storageBucket: "achatsanscontact.appspot.com",
      messagingSenderId: "430340176514",
      appId: "1:430340176514:web:ed10f7092589358527929b",
      measurementId: "G-P76RE2JTJ6"
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }
}
