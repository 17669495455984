<h3>Créer un produit</h3>

<form (ngSubmit)="onSubmitCreateProduct(f)" #f="ngForm">
  <div class="form-group">
    <label for="nom">Nom du produit</label>
    <input type="text" class="form-control" id="nom" name="nom" ngModel required minlength="4">
  </div>
  <div class="form-group">
    <label for="descriptif">Descriptif</label>
    <textarea name="descriptif" id="descriptif" class="form-control" ngModel rows="7"></textarea>
  </div>
  <div class="form-group">
    <label for="descriptif">Prix en €</label>
    <input type="text" class="form-control" id="prix" name="prix" ngModel required>
  </div>

  <div class="form-group">
    <label for="categorie">Catégorie</label>
    <input type="text" class="form-control" id="categorie" name="categorie" ngModel required>
  </div>

  <div class="custom-file">
    <input type="file" class="custom-file-input" id="customFile" (change)="detectFiles($event)" accept="image/*">
    <label class="custom-file-label" for="customFile">Choisissez votre image</label>
    <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
  </div>

  <button class="btn btn-info w-50 float-right mt-3" [disabled]="f.invalid || fileIsUploading">Créer</button>
</form>
