<div class="card o-hidden border-0 shadow mb-5">
  <div class="card-body p-3">
    <div class="text-center">
      <h1 class="h4 text-gray-900 mb-4">Connexion</h1>
    </div>
    <form (ngSubmit)="onSignIn(f)" #f="ngForm">
      <div class="form-group">
        <input type="email" class="form-control form-control-user" name="email" ngModel id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Email">
      </div>
      <div class="form-group">
        <input type="password" class="form-control form-control-user" name="password" ngModel="" id="exampleInputPassword" placeholder="Mot de passe">
      </div>
<!--      <div class="form-group">-->
<!--        <div class="custom-control custom-checkbox small">-->
<!--          <input type="checkbox" class="custom-control-input" id="customCheck">-->
<!--          <label class="custom-control-label" for="customCheck">Se souvenir de moi</label>-->
<!--        </div>-->
<!--      </div>-->
      <button type="submit" class="btn btn-primary btn-user btn-block">
        Se connecter
      </button>
      <p class="text-danger">{{ errorMessage }}</p>
      <hr>
<!--      <button class="btn btn-google btn-user btn-block" (click)="signInWithGoogle()">-->
<!--        <i class="fab fa-google fa-fw"></i> Connexion avec Google-->
<!--      </button>-->
<!--      <a href="index.html" class="btn btn-facebook btn-user btn-block">-->
<!--        <i class="fab fa-facebook-f fa-fw"></i> Connexion avec Facebook-->
<!--      </a>-->
    </form>
    <hr>
    <div class="text-center">
      <a class="small" routerLink="/motdepasse">Mot de passe oublié ?</a>
    </div>
    <div class="text-center mt-2">
      Pas encore inscrit ? <a routerLink="/inscription">Créer un compte</a>
    </div>
  </div>
</div>
