import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;
import {Subject} from 'rxjs';
import {Commandes} from '../modeles/commande.modele';
import {Adresses} from '../modeles/adresse.modele';
import {Produits} from '../modeles/produit.modele';

@Injectable({
  providedIn: 'root'
})
export class MoncompteService {

  constructor() {
    this.getCommandes();
    this.getCommandesAdmin();
    this.getAdresse();
  }

  commandes: Commandes[] = [];
  commandesSubject = new Subject<Commandes[]>();

  commandesAdmin: Commandes[] = [];
  commandesAdminSubject = new Subject<Commandes[]>();

  adresses: Adresses[] = [];
  adressesSubject = new Subject<Adresses[]>();

  emitCommandes() {
    this.commandesSubject.next(this.commandes);
  }
  emitCommandesAdmin() {
    this.commandesAdminSubject.next(this.commandesAdmin);
  }
  emitAdresses() {
    this.adressesSubject.next(this.adresses);
  }

  getCommandes() {
    firebase.database().ref('/commandes').orderByChild('uid').equalTo(firebase.auth().currentUser.uid)
      .on('value', (data: DataSnapshot) => {
          this.commandes = data.val() ? Object.values(data.val()) : [];
          this.emitCommandes();
        }
      );
  }

  getCommandesAdmin() {
    firebase.database().ref('/commandes')
      .on('value', (data: DataSnapshot) => {
          this.commandesAdmin = data.val() ? Object.values(data.val()) : [];
          this.emitCommandesAdmin();
        }
      );
  }

  majCommandes() {
    // firebase.database().ref('/commandes').remove();
  }

  supprimerProduitCommande(commandes: Commandes) {
    const produitCommandeIndexaSupprimer = this.commandes.findIndex(
      (produitEl) => {
        if(produitEl === commandes) {
          return true;
        }
      }
    );
    console.log(commandes);
    // this.commandes.splice(produitCommandeIndexaSupprimer, 1);
    this.emitCommandes();
  }

  getAdresse() {
    firebase.database().ref('/clients' ).child(firebase.auth().currentUser.uid + '/adresses')
      .on('value', (data: DataSnapshot) => {
          this.adresses = data.val() ? Object.values(data.val()) : [];
          this.emitAdresses();
        }
      );
  }

  getSinglCommande(id: number) {
    return new Promise(
      (resolve, reject) => {
        firebase.database().ref('/commandes/' + id).once('value').then(
          (data: DataSnapshot) => {
            resolve(data.val());
          }, (error) => {
            reject(error);
          }
        );
      }
    );
  }

}
