import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {NgForm} from '@angular/forms';
import * as firebase from 'firebase';
import {Produits} from '../modeles/produit.modele';
import {ProduitsService} from '../services/produits.service';
import {Subscription} from 'rxjs';
import {stringify} from 'querystring';

@Component({
  selector: 'app-espacepro',
  templateUrl: './espacepro.component.html',
  styleUrls: ['./espacepro.component.css']
})
export class EspaceproComponent implements OnInit {
  uid: string;
  raccourci: string;
  errorMessage: string;
  produits: Produits[] = [];
  produitsaModifier: Produits[] = [];
  produitsSubscription: any;
  unProduitCle: number;
  unProduit: any;

  constructor(private router: Router, private authService: AuthService,
              public produitsService: ProduitsService) { }

  ngOnInit(): void {
    this.uid = firebase.auth().currentUser.uid;
    this.produitsSubscription = this.produitsService.produitsSubject.subscribe(
      (produits: Produits[]) => {
        this.produits = produits;
      }
    );
    this.produitsService.emitProduits();
  }
  isBoutiqueExiste() {
    return true;
  }
  onModifierProduit(liste) {
    this.unProduitCle = this.produitsService.modifierProduit(liste);
    this.unProduit = liste;
  }
  onCreerProduit() {
    this.unProduit = '';
  }

  onCreerBoutique(f: NgForm) {
    this.raccourci = f.value.nom.split(' ').join('').toLowerCase();
    firebase.database().ref('/clients/' + this.uid + '/boutique').set({
      date: new Date().toISOString(),
      nom: f.value.nom,
      nomCourt: this.raccourci,
      adresse: f.value.adresse,
      cp: f.value.cp,
      ville: f.value.ville
    });
    console.log(f.value);
  }
}
