<div class="row">
  <div class="col-12">
      <span class="h3">Vos piles et produits d’entretien pour aides auditives sans contact !</span><br>
    <p>Pour des raisons sanitaires ou simplement parce que votre temps est précieux, commandez et réglez vos achats en ligne et récupérez le tout dans notre centre <a href="https://www.audition42.fr" target="_blank"><strong>Audition 42</strong></a> à Saint Etienne.</p>
  </div>
</div>

<div class="row mt-2" *ngIf="produits.length === 0">
  <div class="col-lg-3 col-6 mb-3">
    <div class="card mb-4 bg-gray-100">
      <img class="card-img" src="../../assets/images/chargement.gif">
      <div class="card-body text-center">
        <span class="h6 card-title">Chargement en cours</span><br>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6 mb-3">
    <div class="card mb-4 bg-gray-100">
      <img class="card-img" src="../../assets/images/chargement.gif">
      <div class="card-body text-center">
        <span class="h6 card-title">Chargement en cours</span><br>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6 mb-3">
    <div class="card mb-4 bg-gray-100">
      <img class="card-img" src="../../assets/images/chargement.gif">
      <div class="card-body text-center">
        <span class="h6 card-title">Chargement en cours</span><br>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6 mb-3">
    <div class="card mb-4 bg-gray-100">
      <img class="card-img" src="../../assets/images/chargement.gif">
      <div class="card-body text-center">
        <span class="h6 card-title">Chargement en cours</span><br>
      </div>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="col-lg-3 col-6 mb-3" *ngFor="let produitDetail of produits; index as i">
    <div class="card mb-4 bg-gray-100">
      <a routerLink="/produit/{{i}}"><img class="card-img" [src]="produitDetail.image" *ngIf="produitDetail.image"></a>
      <div class="card-body">
        <span class="h6 card-title">{{ produitDetail.nom }}</span><br>
<!--        <span class=""><small>{{ produitDetail.descriptif }}</small></span>-->

        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
          <input type="text" name="prix" hidden [ngModel]="produitDetail.prix">
          <input hidden id="quantite" name="quantite" [ngModel]=produitDetail.quantite>
          <input hidden id="cle" name="cle" [ngModel]=produitDetail>

          Qté
          <i class="far fa-minus-square ml-1" disabled *ngIf="produitDetail.quantite === 1"></i>
          <i class="far fa-minus-square ml-1" (click)="isMoins(produitDetail)" *ngIf="produitDetail.quantite > 1"></i>
          {{ produitDetail.quantite }}
          <i class="far fa-plus-square" (click)="isPlus(produitDetail)"></i>


          <div class="row mt-2">
            <div class="col">
              <div class="price text-primary"><h5 class="mt-2">{{ produitDetail.prix }}€</h5></div>
            </div>
            <div class="col">
              <button class="btn btn-outline-primary w-100" type="submit">
                <i class="fas fa-shopping-cart"></i> Ajouter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>
