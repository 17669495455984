import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PaiementService} from '../services/paiement.service';
import * as firebase from 'firebase';
import {Router} from '@angular/router';
import {MoncompteService} from '../services/moncompte.service';
import {Adresses} from '../modeles/adresse.modele';
import {Subscription} from 'rxjs';
import {GeneralService} from '../services/general.service';

declare var paypal;

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css']
})
export class PaiementComponent implements OnInit {
  uneCommande: any;
  uneCommandePanier: any;
  // totalCommande: string;
  // commandeId: any;
  uid: string;

  fdp: string;
  fdpLivraison: number;

  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;

  product = {
    description: 'Produits Audition42'
  };

  paidFor = false;

  // uneDate = JSON.parse(localStorage.getItem('date'));
  // uneCommandePanier = JSON.parse(localStorage.getItem('commandePanier'));
  commandeId = this.paiementService.getCommandeId(JSON.parse(localStorage.getItem('date')));
  totalCommande = this.paiementService.totalCommande(JSON.parse(localStorage.getItem('commandePanier')));

  constructor(public generalService: GeneralService, private paiementService: PaiementService) {}

  ngOnInit() {
    this.fdp = this.generalService.fdp;
    this.fdpLivraison = this.generalService.fdpLivraison;

    paypal
      .Buttons({
        createOrder: (data, actions) => {

          return actions.order.create({
            purchase_units: [
              {
                description: this.product.description,
                shippingAddressEditable: false,
                amount: {
                  value: this.totalCommande
                }
              }
            ]
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          const uneDate = JSON.parse(localStorage.getItem('date'));
          this.paiementService.majCommande(order, uneDate);
          this.paidFor = true;
          console.log(order);
        },
        onError: err => {
          console.log(err);
        }
      })
      .render(this.paypalElement.nativeElement);
  }
}
