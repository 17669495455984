import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable()
export class AuthService {


  createNewUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }
  signInUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }
  resetPassword(emailAdresse) {
    // const emailAddress = "user@example.com";

    firebase.auth().sendPasswordResetEmail(emailAdresse).then(function() {
      alert('Consulter votre boîte mail pour mettre à jour votre mot de passe');
      return true;
    }).catch(function(error) {
      alert('Vous n\'êtes pas inscrit avec ce mail');
    });
  }

  provider = new firebase.auth.GoogleAuthProvider();

  signInGoogle() {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithPopup(this.provider).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  signOutUser() {
    firebase.auth().signOut();
  }
}
