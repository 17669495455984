import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.css']
})
export class CategorieComponent implements OnInit {
  categorie: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.categorie = this.route.snapshot.paramMap.get('categorie');
  }

}
