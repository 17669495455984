<div class="row justify-content-center">

  <div class="col-xl-10 col-lg-12 col-md-9">

    <div class="card o-hidden border-0 shadow-lg mb-5">
      <div class="card-body p-0">
        <!-- Nested Row within Card Body -->
        <div class="row">
          <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
          <div class="col-lg-7">
            <div class="p-5">
              <div class="text-center">
                <h1 class="h4 text-gray-900 mb-4">Créez un compte</h1>
              </div>
              <form class="user" (ngSubmit)="onSubmit(f)" #f="ngForm">
                <div class="form-group">
                  <input type="email" class="form-control form-control-user" name="email" ngModel id="exampleInputEmail" placeholder="Email" required>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 mb-3 mb-sm-0">
                    <input type="password" class="form-control form-control-user" name="password" ngModel id="exampleInputPassword" placeholder="Mot de passe" required>
                  </div>
                  <div class="col-sm-6">
                    <input type="password" class="form-control form-control-user" name="confirmpassword" ngModel id="exampleRepeatPassword" placeholder="Confirmation de mot de passe" required>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary btn-user btn-block" [disabled]="f.value.password !== f.value.confirmpassword">
                  Créer
                </button>
                <p class="text-danger">{{ errorMessage }}</p>
    <!--            <hr>-->
    <!--            <a href="index.html" class="btn btn-google btn-user btn-block">-->
    <!--              <i class="fab fa-google fa-fw"></i> Compte Google-->
    <!--            </a>-->
    <!--            <a href="index.html" class="btn btn-facebook btn-user btn-block">-->
    <!--              <i class="fab fa-facebook-f fa-fw"></i> Compte Facebook-->
    <!--            </a>-->
              </form>
              <hr>
              <div class="text-center">
                <a class="small" routerLink="/motdepasse">Mot de passe oublié ?</a>
              </div>
              <div class="text-center">
                <a class="small" routerLink="/panier">Vous avez déjà un compte. Se connecter</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
