import { Component, OnInit } from '@angular/core';
import {Produits} from '../modeles/produit.modele';
import {GeneralService} from '../services/general.service';
import {Panier} from '../modeles/panier.modele';
import {NgForm} from '@angular/forms';
import {ProduitsService} from '../services/produits.service';
import {Observable, Subscription} from 'rxjs';
import {Commandes} from '../modeles/commande.modele';
import { HttpClient } from '@angular/common/http';
import {Adresses} from '../modeles/adresse.modele';
import {Categories} from '../modeles/categorie.modele';
import {stringify} from 'querystring';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

  panier: Panier[] = [];
  produits: Produits[] = [];
  produitsSubscription: Subscription;
  categories: Categories[] = [];
  categoriesSubscription: Subscription;

  public onAjouterPanier(cle:string, quantite:number, prix:number) {
    this.generalService.ajouterPanier(cle, quantite, prix);
  }

  onSubmit(f: NgForm) {
    this.generalService.ajouterPanier(f.value.cle, f.value.quantite, f.value.prix);
    const element =  document.querySelector('.btn-panier');
    if (element != null) {
      element.classList.remove('btn-warning');
      element.classList.add('animated', 'tada', 'btn-success');
      setTimeout(function(){
        element.classList.remove('animated', 'tada', 'btn-success');
        element.classList.add('btn-warning');
      }, 300);
    }
  }
  isPlus(ligne) {
    const numeroLigne = this.produitsService.produits.findIndex(
      (panierEl) => {
        if(panierEl === ligne) {
          return true;
        }
      }
    );
    this.produitsService.produits[numeroLigne].quantite = this.produitsService.produits[numeroLigne].quantite + 1;
    localStorage.setItem('produit', JSON.stringify(this.produitsService.produits));
  }
  isMoins(ligne) {
    const numeroLigne = this.produitsService.produits.findIndex(
      (panierEl) => {
        if(panierEl === ligne) {
          return true;
        }
      }
    );
    this.produitsService.produits[numeroLigne].quantite = this.produitsService.produits[numeroLigne].quantite - 1;
    localStorage.setItem('produit', JSON.stringify(this.produitsService.produits));
  }

  constructor(private generalService: GeneralService, private produitsService : ProduitsService, private http: HttpClient) { }

  ngOnInit(): void {
    this.produitsSubscription = this.produitsService.produitsSubject.subscribe(
      (produits: Produits[]) => {
        this.produits = produits;
      }
    );
    this.produitsService.emitProduits();
  }


}
