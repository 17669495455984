<div class="card border-left-primary shadow py-2 mt-2">
  <div class="card-body">
    <div class="row no-gutters align-items-center">
      <div class="col mr-2">
        <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Frais de livraison</div>
        <div class="h6 mb-0 font-weight-bold text-gray-800">à Audition42 : gratuit</div>
        <div class="h6 mb-0 text-gray-800">voie postale : 5.90€</div>
        <div class="h6 mb-0 text-gray-800">à partir de 40€ d'achat : gratuit</div>
      </div>

      <div class="col-auto">
        <i class="fas fa-shopping-bag fa-2x text-gray-400"></i><br>
      </div>

      <div class="col-12 mt-3 font-weight-bold text-gray-800" *ngIf="!adresses">
        <div class="text-sm font-weight-bold text-primary text-uppercase">Adresse de facturation</div>
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
          <input type="hidden" name="panier" [ngModel]="this.generalService.panier">
          <input type="hidden" name="uid" [ngModel]="this.uid">
          <input type="hidden" name="email" [ngModel]="this.email">
          <div class="form-group">
            <input type="radio" class="mr-2" name="livraison" id="livraison" required value="surplace" (change)="this.generalService.fdpAjouter('surplace')"> à récupérer à Audition42 <i class="far fa-question-circle"></i><br>
            <input type="radio" class="mr-2" name="livraison" id="livraison" required value="livraison" (change)="this.generalService.fdpAjouter('livraison')"> livraison par courrier
          </div>
          <div class="form-group">
            <label for="nom">Nom</label>
            <input type="text" class="form-control" name="nom" id="nom" ngModel required>
          </div>
          <div class="form-group">
            <label for="adresse">Adresse</label>
            <input type="text" class="form-control" name="adresse" id="adresse" ngModel required>
          </div>
          <div class="form-group">
            <label for="cp">Code postal</label>
            <input type="text" class="form-control" name="cp" id="cp" ngModel required>
          </div>
          <div class="form-group">
            <label for="ville">Ville</label>
            <input type="text" class="form-control" name="ville" id="ville" ngModel required>
          </div>
          <button type="submit" class="btn btn-primary w-100" [disabled]="f.invalid">Procédez au paiement</button>
        </form>
      </div>


      <div class="col-12 mt-3 font-weight-bold text-gray-800" *ngIf="adresses">
        <div class="text-sm font-weight-bold text-primary text-uppercase">Adresse de facturation</div>
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
          <input type="hidden" name="panier" [ngModel]="this.generalService.panier">
          <input type="hidden" name="uid" [ngModel]="this.uid">
          <input type="hidden" name="email" [ngModel]="this.email">
          <div class="form-group">
            <input type="radio" class="mr-2" name="livraison" id="livraison" ngModel required value="surplace" (change)="this.generalService.fdpAjouter('surplace')"> à récupérer à Audition42 <i class="far fa-question-circle"></i><br>
            <input type="radio" class="mr-2" name="livraison" id="livraison" ngModel required value="livraison" (change)="this.generalService.fdpAjouter('livraison')"> livraison par courrier
          </div>
          <div class="form-group">
            <label for="nom">Nom</label>
            <input type="text" class="form-control" name="nom" id="nom" [ngModel]="adresses[0]?.nom" required>
          </div>
          <div class="form-group">
            <label for="adresse">Adresse</label>
            <input type="text" class="form-control" name="adresse" id="adresse" [ngModel]="adresses[0]?.adresse" required>
          </div>
          <div class="form-group">
            <label for="cp">Code postal</label>
            <input type="text" class="form-control" name="cp" id="cp" [ngModel]="adresses[0]?.cp" required>
          </div>
          <div class="form-group">
            <label for="ville">Ville</label>
            <input type="text" class="form-control" name="ville" id="ville" [ngModel]="adresses[0]?.ville" required>
          </div>
          <button type="submit" class="btn btn-primary w-100" [disabled]="f.invalid">Procédez au paiement</button>
        </form>
      </div>
    </div>
  </div>
</div>
