<div class="row">

  <div class="col-12 col-lg-3">
    <div class="card mb-2">
      <div class="card-header">Mon compte</div>
      <div class="card-body">
        <i class="far fa-envelope"></i> {{ email }}<hr>
        <i class="far fa-address-card"></i> adresse<br>
        {{ adresses[0].adresse }}<br>
        {{ adresses[0].cp }} {{ adresses[0].ville }}
      </div>
    </div>
    <pre>{{ detailCommande | json }}</pre>
  </div>

  <div class="col-12 col-lg-9">
    <h3>Mes commandes</h3>

    <div [class.border-left-warning]="retour.statut == 'attente'" class="card border border-left-success p-2 mb-2" *ngFor="let retour of commandes.reverse(); let i = index">
      <div class="card-body">
        <div class="row">

          <div class="col-6">
            <span class="h4">Le {{ retour.date | date:'dd/MM/yyyy à HH:mm' }}</span><br>
            Mode de distribution : <strong>{{retour.livraison }}</strong><br>
            <button class="btn btn-warning btn-sm" (click)="onPayer(retour)" *ngIf="retour.statut === 'attente'">
              <i class="fas fa-euro-sign"></i> Payer</button>
          </div>

          <div class="col-6">
            <div class="col-12" *ngFor="let panierDetail of retour.panier">
              <div class="row border p-2">
                <div class="col-12">{{ panierDetail.cle.nom }}</div>
                <div class="col">{{ panierDetail.prix }}€</div>
                <div class="col">x {{ panierDetail.quantite }}</div>
                <div class="col font-weight-bold price text-primary">{{ panierDetail.quantite * panierDetail.prix }}€</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</div>
