<div class="row">
  <div class="col-12">
    <span class="h3">Votre panier</span>
  </div>
</div>

<div class="row mt-3 mb-5">
  <div class="col-lg-8 col-12">
    <div class="row">
      <div class="col-lg-12 col-12 mb-2" *ngFor="let panierDetail of this.generalService.panier">
        <div class="card border-left-primary shadow">
          <div class="card-body">
            <p class="h4">{{ panierDetail.cle.nom }}
              <span class="float-right">
                <button class="btn btn-secondary btn-circle btn-sm" (click)="this.generalService.panierSupprimer( panierDetail )">
                  <i class="fas fa-trash"></i>
                </button>
              </span>
            </p>
            <p>{{ panierDetail.cle.descriptif }}</p>
            <div class="h4 text-primary">
              Quantité :
              <i class="far fa-minus-square text-primary" *ngIf="panierDetail.quantite === 1"></i>
              <i class="far fa-minus-square text-primary" (click)="isMoins(panierDetail)" *ngIf="panierDetail.quantite > 1"></i>
              {{ panierDetail.quantite }}
              <i class="far fa-plus-square text-primary" (click)="isPlus(panierDetail)"></i>

              <span class="h4 mb-0 font-weight-bold text-gray-800 float-right">
              <small>total</small>
                {{ this.generalService.totalLigne( panierDetail.cle.prix, panierDetail.quantite) }}€
            </span>
            </div>

          </div>
        </div>
      </div>
      <div class="col-12 mb-2" *ngIf="!isAuth">
        <div class="card border-left-primary shadow py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Total de votre commande</div>
                <div class="h2 mb-0 font-weight-bold text-gray-800">{{ this.generalService.totalPanier() }}€</div>
                <div class="mb-0 text-success" *ngIf="this.generalService.fdp === 'livraison' && this.generalService.fdpLivraison === 0"><i class="far fa-check-circle"></i> Livraison offerte</div>
                <div class="mb-0 text-success" *ngIf="this.generalService.fdp === 'livraison' && this.generalService.fdpLivraison != 0"><i class="fas fa-shipping-fast"></i> dont livraison {{this.generalService.fdpLivraison}}€</div>
                <div class="mb-0 text-success"><i class="far fa-comment-alt"></i> sans frais pour le retrait en boutique</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-euro-sign fa-2x text-gray-400"></i><br>
              </div>
            </div>
            <p>Merci de vous inscrire ou de vous connecter</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-4 col-12" *ngIf="!isAuth">
    <app-signin></app-signin>
  </div>
  <div class="col-lg-4 col-12" *ngIf="isAuth">
    <div class="card border-left-primary shadow py-2">
      <div class="card-body">
        <div class="row no-gutters align-items-center">
          <div class="col mr-2">
            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Total de votre commande</div>
            <div class="h4 mb-0 font-weight-bold text-gray-800">{{ this.generalService.totalPanier() }}€</div>
            <div class="mb-0 text-success" *ngIf="this.generalService.fdp === 'livraison' && this.generalService.fdpLivraison === 0"><i class="far fa-check-circle"></i> Livraison offerte</div>
            <div class="mb-0 text-success" *ngIf="this.generalService.fdp === 'livraison' && this.generalService.fdpLivraison != 0"><i class="fas fa-shipping-fast"></i> dont livraison {{this.generalService.fdpLivraison}}€</div>
         </div>
          <div class="col-auto">
            <i class="fas fa-euro-sign fa-2x text-gray-400"></i><br>
          </div>
        </div>
      </div>
    </div>

    <app-livraison></app-livraison>
  </div>
</div>
