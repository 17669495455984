import {Component, Input, OnInit} from '@angular/core';
import {GeneralService} from '../services/general.service';
import {Router} from '@angular/router';
import * as firebase from 'firebase';

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.css']
})
export class PanierComponent implements OnInit {
  @Input() fdp = this.generalService.fdp;
  isVide() {
    if (this.generalService.panier.length === 0) {
      this.router.navigateByUrl('');
    }
  }
  isPlus(ligne) {
    const numeroLigne = this.generalService.panier.findIndex(
      (panierEl) => {
        if(panierEl === ligne) {
          return true;
        }
      }
    );
    this.generalService.panier[numeroLigne].quantite = this.generalService.panier[numeroLigne].quantite + 1;
    localStorage.setItem('panier', JSON.stringify(this.generalService.panier));
    const element =  document.querySelector('.btn-panier');
    if (element != null) {
      element.classList.add('animated', 'tada');
      setTimeout(function(){
        element.classList.remove('animated', 'tada');
      }, 300);
    }
  }
  isMoins(ligne) {
    const numeroLigne = this.generalService.panier.findIndex(
      (panierEl) => {
        if(panierEl === ligne) {
          return true;
        }
      }
    );
    this.generalService.panier[numeroLigne].quantite = this.generalService.panier[numeroLigne].quantite - 1;
    localStorage.setItem('panier', JSON.stringify(this.generalService.panier));
    const element =  document.querySelector('.btn-panier');
    if (element != null) {
      element.classList.add('animated', 'tada');
      setTimeout(function(){
        element.classList.remove('animated', 'tada');
      }, 300);
    }
  }

  isAuth: boolean;
  email: string;

  constructor(public generalService: GeneralService,  private router: Router) { }

  ngOnInit(): void {
    this.isVide();

    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          this.isAuth = true;
          this.email = user.email;

        } else {
          this.isAuth = false;
        }
      }
    );
  }

}
