import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AccueilComponent } from './accueil/accueil.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PanierComponent } from './panier/panier.component';
import { ProduitsComponent } from './produits/produits.component';
import {RouterModule, Routes} from '@angular/router';
import {GeneralService} from './services/general.service';
import {FormsModule} from '@angular/forms';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { MotdepasseOublieComponent } from './motdepasse-oublie/motdepasse-oublie.component';
import {ProduitsService} from './services/produits.service';
import {AuthService} from './services/auth.service';
import {AuthGuardService} from './services/auth-guard.service';
import { LivraisonComponent } from './livraison/livraison.component';
import { MoncompteComponent } from './moncompte/moncompte.component';
import { PaiementComponent } from './paiement/paiement.component';

import { EspaceproComponent } from './espacepro/espacepro.component';
import { ProduitCreerComponent } from './espacepro/produit-creer/produit-creer.component';
import { ProduitModifierComponent } from './espacepro/produit-modifier/produit-modifier.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CgvComponent } from './cgv/cgv.component';
import { FooterComponent } from './footer/footer.component';
import { ProduitComponent } from './produit/produit.component';
import { CommandesComponent } from './commandes/commandes.component';
import { CategorieComponent } from './categorie/categorie.component';

const appRoutes: Routes = [
  { path: 'accueil', component: AccueilComponent },
  { path: 'produits', component: ProduitsComponent },
  { path: 'produit/:id', component: ProduitComponent },
  { path: 'categorie/:categorie', component: CategorieComponent },
  { path: 'cgv', component: CgvComponent },
  { path: 'connexion', component: SigninComponent },
  { path: 'panier', component: PanierComponent },
  { path: 'paiement', component: PaiementComponent },
  { path: 'moncompte', canActivate: [AuthGuardService], component: MoncompteComponent },
  { path: 'inscription', component: SignupComponent },
  { path: 'espacepro', canActivate: [AuthGuardService], component: EspaceproComponent },
  { path: 'commandes', canActivate: [AuthGuardService], component: CommandesComponent },
  { path: 'motdepasse', component: MotdepasseOublieComponent },
  { path: '', component: AccueilComponent },
  { path: '**', component: AccueilComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    NavbarComponent,
    PanierComponent,
    ProduitsComponent,
    SigninComponent,
    SignupComponent,
    MotdepasseOublieComponent,
    LivraisonComponent,
    MoncompteComponent,
    PaiementComponent,
    EspaceproComponent,
    ProduitCreerComponent,
    ProduitModifierComponent,
    CgvComponent,
    FooterComponent,
    ProduitComponent,
    CommandesComponent,
    CategorieComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    NgbModule
  ],
  providers: [
    GeneralService,
    ProduitsService,
    AuthService,
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
