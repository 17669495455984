import { Injectable } from '@angular/core';
import {Panier} from '../modeles/panier.modele';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  panier: Panier[] = JSON.parse(localStorage.getItem('panier')) || [];
  fdp: string;
  fdpLivraison: number;

  public ajouterPanier(cle: string, quantite: number, prix: number) {
    this.panier.push({ cle, quantite, prix });
    localStorage.setItem('panier', JSON.stringify(this.panier));
  }
  public totalPanier() {
    let total = 0;
    for(let data of this.panier){
      total += data.quantite * data.prix;
    }
    if (this.fdp === 'livraison' && total < 40) {
      this.fdpLivraison = 5.9;
      total += this.fdpLivraison;
    } else if (this.fdp === 'livraison' && total >= 40) {
      this.fdpLivraison = 0;
    }
    return this.formatPrice(total);
  }
  public totalLigne(quantite, prix) {
    let stotal = quantite * prix;
    return this.formatPrice(stotal);
  }

  fdpAjouter(mode) {
    this.fdp = mode;
    localStorage.setItem('fdp', mode);
  }


  formatPrice(value) {
    let val = ( value / 1 );
    return val.toFixed(2);
  }
  panierSupprimer(panier: Panier) {
    const panierIndexAsupprimer = this.panier.findIndex(
      (panierEl) => {
        if(panierEl === panier) {
          return true;
        }
      }
    );
    this.panier.splice(panierIndexAsupprimer, 1);
    localStorage.setItem('panier', JSON.stringify(this.panier));
    const element =  document.querySelector('.btn-panier');
    if (element != null) {
      element.classList.add('animated', 'tada');
      setTimeout(function(){
        element.classList.remove('animated', 'tada');
      }, 300);
    }
  }

  constructor(private http: HttpClient) { }
}
