import {Component, Input, OnInit} from '@angular/core';
import {Produits} from '../../modeles/produit.modele';
import {EspaceproComponent} from '../espacepro.component';
import * as firebase from 'firebase';
import {ProduitsService} from '../../services/produits.service';

@Component({
  selector: 'app-produit-modifier',
  templateUrl: './produit-modifier.component.html',
  styleUrls: ['./produit-modifier.component.css']
})
export class ProduitModifierComponent implements OnInit {
  fileIsUploading: boolean;
  fileUrl: string;
  fileUploaded = false;
  retourImage: string = '';
  unProduit: Produits[];
  unProduitCle: number;

  constructor(public espaceproComponent: EspaceproComponent, public produitsService: ProduitsService) { }

  onSubmitModifierProduit(f) {
    // if(this.fileUrl && this.fileUrl !== '') {
    //   this.retourImage = this.fileUrl;
    // }

    firebase.database().ref('/clients/FFTj8FBH6OTwGvSr1sYgSQ55E1H3/boutique/produits/' + this.espaceproComponent.unProduitCle).update({
      nom: f.value.nom,
      prix: f.value.prix,
      categorie: f.value.categorie,
      descriptif: f.value.descriptif
    });

  }

  onUploadFile(file: File) {
    this.fileIsUploading = true;
    this.produitsService.uploadFile(file).then(
      (url: string) => {
        this.fileUrl = url;
        this.fileIsUploading = false;
        this.fileUploaded = true;
      }
    );
  }

  detectFiles(event) {
    this.onUploadFile(event.target.files[0]);
  }

  ngOnInit(): void {
  }

}
