<div class="row justify-content-center">

  <div class="col-xl-10 col-lg-12 col-md-9">

    <div class="card o-hidden border-0 shadow-lg my-5">
      <div class="card-body p-0">
        <!-- Nested Row within Card Body -->
        <div class="row">
          <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
          <div class="col-lg-6">
            <div class="p-5">
              <div class="text-center">
                <h1 class="h4 text-gray-900 mb-2">Mot de passe oublié ?</h1>
                <p class="mb-4">Merci d'indiquer votre mail pour pouvoir changer votre mot de passe</p>
              </div>
              <form class="user" (ngSubmit)="onResetPassword(f)" #f="ngForm">
                <div class="form-group">
                  <input type="email"
                         class="form-control form-control-user"
                         name="email"
                         id="exampleInputEmail"
                         aria-describedby="emailHelp"
                         placeholder="Votre adresse mail"
                         ngModel>
                </div>
                <button type="submit" class="btn btn-primary btn-user btn-block">
                  Changer de mot de passe
                </button>
              </form>
              <hr>
              <div class="text-center">
                <a class="small" routerLink="/inscription">Créer un compte</a>
              </div>
              <div class="text-center">
                <a class="small"routerLink="/panier">Se connecter</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
