import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-motdepasse-oublie',
  templateUrl: './motdepasse-oublie.component.html',
  styleUrls: ['./motdepasse-oublie.component.css']
})
export class MotdepasseOublieComponent implements OnInit {

  onResetPassword(f: NgForm) {
    this.authService.resetPassword(f.value.email);
    this.router.navigate(['/connexion']);
  }

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

}
