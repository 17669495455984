import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  errorMessage: string;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  onSubmit(f: NgForm) {
    this.authService.createNewUser(f.value.email, f.value.password).then(
      () => {
        this.router.navigate(['/panier']);
      },
      (error) => {
        this.errorMessage = error;
      }
    );
  }

}
