import { Component, OnInit } from '@angular/core';
import {Produits} from '../../modeles/produit.modele';
import {ProduitsService} from '../../services/produits.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-produit-creer',
  templateUrl: './produit-creer.component.html',
  styleUrls: ['./produit-creer.component.css']
})
export class ProduitCreerComponent implements OnInit {
  produit: Produits[];
  fileIsUploading = false;
  fileUrl: string;
  fileUploaded = false;
  uid: string;
  retourImage: string = '';

  onSubmitCreateProduct(f) {
    console.log(f.value);
    if(this.fileUrl && this.fileUrl !== '') {
      this.retourImage = this.fileUrl;
    }
    firebase.database().ref('/clients'  ).child(this.uid + '/boutique/produits/').push({
      date: new Date().toISOString(),
      nom: f.value.nom,
      prix: f.value.prix,
      quantite: 1,
      descriptif: f.value.descriptif,
      categorie: f.value.categorie,
      image: this.retourImage,
      statut: 'visible'
    });
  }

  onUploadFile(file: File) {
    this.fileIsUploading = true;
    this.produitsService.uploadFile(file).then(
      (url: string) => {
        this.fileUrl = url;
        this.fileIsUploading = false;
        this.fileUploaded = true;
      }
    );
  }
  detectFiles(event) {
    this.onUploadFile(event.target.files[0]);
  }

  constructor(private produitsService: ProduitsService) { }

  ngOnInit(): void {
    this.uid = firebase.auth().currentUser.uid;
  }

}
