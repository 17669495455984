import { Injectable } from '@angular/core';
import {Produits} from '../modeles/produit.modele';
import * as firebase from 'firebase';
import {Adresses} from '../modeles/adresse.modele';
import {Subject} from 'rxjs';
import DataSnapshot = firebase.database.DataSnapshot;
import {Categories} from '../modeles/categorie.modele';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {stringify} from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class ProduitsService {
  produits: Produits[] = [];
  produitsSubject = new Subject<Produits[]>();
  categories: any;
  categoriesSubject = new Subject<Categories[]>();

  getProduits() {
    firebase.database().ref('/clients/FFTj8FBH6OTwGvSr1sYgSQ55E1H3/boutique/produits')
      .on('value', (data: DataSnapshot) => {
          this.produits = data.val() ? Object.values(data.val()) : [];
          this.emitProduits();
        }
      );
  }
  emitProduits() {
    this.produitsSubject.next(this.produits);
  }
  getCategoriesFromServer() {
    this.httpClient
      // .get<any[]>('https://www.robinpretaporter.fr/admin/tmp/categories.json')
      .get<any[]>('https://jsonplaceholder.typicode.com/users')
      .subscribe(
        (response) => {
          this.categories = response;
          console.log(response);
          this.emitCategories();
        },
        (error) => {
          console.log('Erreur ! : ' + stringify(error));
        }
      );
  }
  emitCategories() {
    this.categoriesSubject.next(this.categories);
  }

  uploadFile(file: File) {
    return new Promise(
      (resolve, reject) => {
        const almostUniqueFileName = Date.now().toString();
        const upload = firebase.storage().ref()
          .child('images/' + almostUniqueFileName + file.name).put(file);
        upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
          () => {
            console.log('Chargement…');
          },
          (error) => {
            console.log('Erreur de chargement ! : ' + error);
            reject();
          },
          () => {
            resolve(upload.snapshot.ref.getDownloadURL());
          }
        );
      }
    );
  }

  enregistrerProduits() {
    firebase.database().ref('/clients/FFTj8FBH6OTwGvSr1sYgSQ55E1H3/boutique/produits').set(this.produits);
  }

  supprimerProduit(produits: Produits) {
    if (confirm('Effacé le produit ?\r\n' + produits.nom)) {
      if(produits.image) {
        const storageRef = firebase.storage().refFromURL(produits.image);
        storageRef.delete().then(
          () => {
            console.log('Photo removed!');
          },
          (error) => {
            console.log('Could not remove photo! : ' + error);
          }
        );
      }
      const produitIndexaSupprimer = this.produits.findIndex(
        (produitEl) => {
          if(produitEl === produits) {
            return true;
          }
        }
      );
      this.produits.splice(produitIndexaSupprimer, 1);
      this.enregistrerProduits();
      this.emitProduits();
    }
  }

  modifierProduit(produits: Produits) {
    const produitIndexaModifier = this.produits.findIndex(
      (produitEl) => {
        if(produitEl === produits) {
          return true;
        }
      }
    );
    return produitIndexaModifier;
  }

  constructor(private httpClient: HttpClient) {
    this.getProduits();
  }
}
