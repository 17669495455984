<h3>Modifier un produit ({{ this.espaceproComponent.unProduitCle }})</h3>

<form (ngSubmit)="onSubmitModifierProduit(f)" #f="ngForm">
  <div class="form-group">
    <label for="nom">Nom du produit</label>
    <input type="text" class="form-control" id="nom" name="nom" [ngModel]="this.espaceproComponent.unProduit.nom" required minlength="4">
  </div>
  <div class="form-group">
    <label for="descriptif">Descriptif</label>
    <textarea name="descriptif" id="descriptif" class="form-control" [ngModel]="this.espaceproComponent.unProduit.descriptif" rows="7"></textarea>
  </div>
  <div class="form-group">
    <label for="prix">Prix en €</label>
    <input type="text" class="form-control" id="prix" name="prix" [ngModel]="this.espaceproComponent.unProduit.prix" required>
  </div>

  <div class="form-group">
    <label for="categorie">Catégorie</label>
    <input type="text" class="form-control" id="categorie" name="categorie" [ngModel]="this.espaceproComponent.unProduit.categorie" required>
  </div>

<!--  <div class="custom-file">-->
<!--    <input type="file" class="custom-file-input" id="customFile" (change)="detectFiles($event)" accept="image/*">-->
<!--    <label class="custom-file-label" for="customFile">Choisissez votre image</label>-->
<!--    <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>-->
<!--  </div>-->

  <div class="col-4 mt-2">
    <img src="{{ this.espaceproComponent.unProduit.image }}" alt="illutration" class="img-fluid">
  </div>

  <button class="btn btn-info w-50 float-right mt-3" [disabled]="f.invalid || fileIsUploading">Modifier</button>
</form>
