<div class="container sticky-top">
  <nav class="navbar navbar-expand-lg navbar-light shadow-sm bg-white mt-3">
    <a class="navbar-brand" routerLink=""><img src="../../assets/logo.png" alt="{{ title }}"></a>

    <div ngbDropdown class="d-inline-block" *ngIf="isAuth === true">
      <button class="navbar-toggler" id="dropdownBasic1" ngbDropdownToggle>
        <i class="far fa-user text-success"></i>
        <span class="text-warning" *ngIf="email === 'galifi@yahoo.fr'"><i class="fas fa-star"></i></span>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem routerLink="/espacepro" *ngIf="email === 'galifi@yahoo.fr'"><i class="fas fa-receipt"></i> Espace pro</button>
        <button ngbDropdownItem routerLink="/moncompte"><i class="fas fa-receipt"></i> Commandes</button>
        <button ngbDropdownItem (click)="onSignOut()"><i class="fas fa-sign-out-alt"></i> Déconnexion</button>
      </div>
    </div>

    <div ngbDropdown class="d-inline-block" *ngIf="isAuth === false">
      <button class="navbar-toggler" id="dropdownBasic1" ngbDropdownToggle>
        <i class="far fa-user"></i>
        <span class="text-warning" *ngIf="email === 'galifi@yahoo.fr'"><i class="fas fa-star"></i></span>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem routerLink="/connexion">Connexion</button>
      </div>
    </div>

    <button type="button" class="btn btn-warning btn-sm d-block d-sm-none" routerLink="/panier"
            *ngIf="this.generalService.panier.length">
      <i class="fas fa-cart-arrow-down"></i>
      <span class="badge badge-light ml-2">{{ this.generalService.panier.length }}</span></button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/connexion" *ngIf="isAuth === false">Connexion</a>
        </li>
<!--        <li class="nav-item dropdown" *ngIf="isAuth === true">-->
<!--          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--            <i class="far fa-user"></i> Mon compte-->
<!--            <span class="text-warning" *ngIf="email === 'galifi@yahoo.fr'"><i class="fas fa-star"></i> ADMIN</span>-->
<!--          </a>-->
<!--          <div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
<!--            <a class="dropdown-item" routerLink="/espacepro" *ngIf="email === 'galifi@yahoo.fr'"><i class="fas fa-receipt"></i> Espace pro</a>-->
<!--            <div class="dropdown-divider" *ngIf="email === 'galifi@yahoo.fr'"></div>-->
<!--            <a class="dropdown-item" routerLink="/moncompte"><i class="fas fa-receipt"></i> Commandes</a>-->
<!--            <div class="dropdown-divider"></div>-->
<!--            <a class="dropdown-item" (click)="onSignOut()"><i class="fas fa-sign-out-alt"></i> Déconnexion</a>-->
<!--          </div>-->
<!--        </li>-->
      </ul>
      <div class="col" *ngIf="isAuth === true">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-white" id="dropdownBasic1" ngbDropdownToggle>
            <i class="far fa-user"></i> Mon compte
            <span class="text-warning" *ngIf="email === 'galifi@yahoo.fr'"><i class="fas fa-star"></i> ADMIN</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem routerLink="/commandes" *ngIf="email === 'galifi@yahoo.fr'" class="text-info"><i class="fas fa-receipt"></i> Commandes reçues</button>
            <button ngbDropdownItem routerLink="/espacepro" *ngIf="email === 'galifi@yahoo.fr'" class="text-info"><i class="fas fa-receipt"></i> Nos produits</button>
            <button ngbDropdownItem routerLink="/moncompte" *ngIf="email != 'galifi@yahoo.fr'"><i class="fas fa-receipt"></i> Commandes</button>
            <button ngbDropdownItem (click)="onSignOut()"><i class="fas fa-sign-out-alt"></i> Déconnexion</button>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-warning btn-panier" routerLink="/panier"
              *ngIf="this.generalService.panier.length">
        <i class="fas fa-cart-arrow-down"></i>
        <span class="badge badge-light ml-2">{{ this.generalService.panier.length }}</span>
        {{ this.generalService.totalPanier() }} €</button>
    </div>
  </nav>
</div>
