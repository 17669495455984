import { Component, OnInit } from '@angular/core';
import {MoncompteService} from '../services/moncompte.service';
import {Commandes} from '../modeles/commande.modele';
import {Subscription} from 'rxjs';
import {AuthService} from '../services/auth.service';
import * as firebase from 'firebase';
import {Adresses} from '../modeles/adresse.modele';
import {Router} from '@angular/router';
import {stringify} from 'querystring';

@Component({
  selector: 'app-moncompte',
  templateUrl: './moncompte.component.html',
  styleUrls: ['./moncompte.component.css']
})
export class MoncompteComponent implements OnInit {
  commandes: Commandes[];
  uneCommande: any;
  commandesSubscription: Subscription;
  adresses: Adresses[];
  adressesSubscription: Subscription;
  uid: string = firebase.auth().currentUser.uid;
  email: string = firebase.auth().currentUser.email;
  commandeDate: string;
  detailCommande: any;

  constructor(private router: Router, public moncompteService: MoncompteService) { }
  onPayer(commande) {
    localStorage.setItem('fdp', commande.livraison);
    localStorage.setItem('date', JSON.stringify(commande.date));
    localStorage.setItem('commandePanier', JSON.stringify(commande.panier));
    this.router.navigateByUrl('/paiement');
  }

  onAnnuler(commande) {
    this.detailCommande = commande;
    this.moncompteService.supprimerProduitCommande(commande);
  }

  ngOnInit(): void {
    this.commandesSubscription = this.moncompteService.commandesSubject.subscribe(
      (commandes: Commandes[]) => {
        this.commandes = commandes;
      }
    );
    this.moncompteService.emitCommandes();

    this.adressesSubscription = this.moncompteService.adressesSubject.subscribe(
      (adresses: Adresses[]) => {
        this.adresses = adresses;
      }
    );
    this.moncompteService.emitAdresses();
  }

}
