<div class="row">
  <div class="col-lg-6 col-12 text-center" *ngIf="!paidFor">
    <h2>Montant de la commande</h2>
    <h3 class="text-success"><strong>{{ this.totalCommande }} €</strong></h3>
  </div>

  <div class="col-12" *ngIf="paidFor">
    <h2 class="text-success">Merci votre règlement a été pris en compte</h2>
  </div>

  <div class="col-lg-6 col-12">
    <div #paypal></div>
  </div>
</div>
