<div class="container"*ngIf="uid === 'FFTj8FBH6OTwGvSr1sYgSQ55E1H3'" class="mb-5">
  <div class="row">
    <div class="col-12 col-lg-9">
      <h3>Liste des commandes</h3>

      <div *ngFor="let retour of commandesAdmin.reverse(); let i = index">
        <div [class.border-left-warning]="retour.statut == 'attente'" class="card border border-left-success p-2 mb-2" *ngIf="retour.statut != 'attente'">
          <div class="card-body">
            <div class="row">

              <div class="col-6">
                <span class="h4">Le {{ retour.date | date:'dd/MM/yyyy à HH:mm' }}</span><br>
                Mode de distribution : <strong>{{retour.livraison }}</strong><br>

                <div class="font-weight-bold mt-2 border-left-secondary pl-2"><i class="far fa-user"></i> {{ retour.statut.payer.name.surname | uppercase }} {{ retour.statut.payer.name.given_name }}</div>
              </div>

              <div class="col-6">
                <div class="col-12" *ngFor="let panierDetail of retour.panier">
                  <div class="row border p-2">
                    <div class="col-12">{{ panierDetail.cle.nom }}</div>
                    <div class="col">{{ panierDetail.prix }}€</div>
                    <div class="col">x {{ panierDetail.quantite }}</div>
                    <div class="col font-weight-bold price text-primary">{{ panierDetail.quantite * panierDetail.prix }}€</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      </div>


  </div>
</div>
