import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProduitsService} from '../services/produits.service';
import {Produits} from '../modeles/produit.modele';
import {Subject, Subscription} from 'rxjs';
import {NgForm} from '@angular/forms';
import {GeneralService} from '../services/general.service';

@Component({
  selector: 'app-produit',
  templateUrl: './produit.component.html',
  styleUrls: ['./produit.component.css']
})
export class ProduitComponent implements OnInit {
  id: string;
  produits: Produits[] = [];
  produitsSubject = new Subject<Produits[]>();
  produitsSubscription: Subscription;
  retour: any = this.produitsService.getProduits();

  constructor(private router: Router, private generalService: GeneralService, private route: ActivatedRoute,  private produitsService: ProduitsService) {}

  onSubmit(f: NgForm) {
    this.generalService.ajouterPanier(f.value.cle, f.value.quantite, f.value.prix);
    const element =  document.querySelector('.btn-panier');
    if (element != null) {
      element.classList.remove('btn-warning');
      element.classList.add('animated', 'tada', 'btn-success');
      setTimeout(function(){
        element.classList.remove('animated', 'tada', 'btn-success');
        element.classList.add('btn-warning');
      }, 300);
    }
    this.router.navigate(['/']);
  }
  isPlus(ligne) {
    const numeroLigne = this.produitsService.produits.findIndex(
      (panierEl) => {
        if(panierEl === ligne) {
          return true;
        }
      }
    );
    this.produitsService.produits[numeroLigne].quantite = this.produitsService.produits[numeroLigne].quantite + 1;
    localStorage.setItem('produit', JSON.stringify(this.produitsService.produits));
  }
  isMoins(ligne) {
    const numeroLigne = this.produitsService.produits.findIndex(
      (panierEl) => {
        if(panierEl === ligne) {
          return true;
        }
      }
    );
    this.produitsService.produits[numeroLigne].quantite = this.produitsService.produits[numeroLigne].quantite - 1;
    localStorage.setItem('produit', JSON.stringify(this.produitsService.produits));
  }


  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.produitsSubscription = this.produitsService.produitsSubject.subscribe(
      (produits: Produits[]) => {
        this.produits = produits;
      }
    );
    this.produitsService.emitProduits();
  }

}
