<div class="container">
  <div class="row mb-5">
    <div class="col-12 mb-4">
      <button class="btn btn-light btn-icon-split btn-sm">
                  <span class="icon text-gray-600">
                    <i class="far fa-folder-open"></i>
                  </span>
        <span class="text">{{ produits[id].categorie }}</span>
      </button>
    </div>

    <div class="col-lg-4 col-12">
      <img src="{{ produits[id].image }}" alt="{{ produits[id].nom }}" class="img-fluid shadow-lg">
    </div>
    <div class="col-lg-8 col-12">
      <h3>{{ produits[id].nom }}</h3>
      <p>{{ produits[id].descriptif }}</p>

      <div class="price text-primary"><h3 class="mt-2">{{ produits[id].prix }}€</h3></div>

      <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <input type="text" name="prix" hidden [ngModel]="produits[id].prix">
        <input hidden id="quantite" name="quantite" [ngModel]=produits[id].quantite>
        <input hidden id="cle" name="cle" [ngModel]=produits[id]>

      Qté
      <i class="far fa-minus-square ml-1" disabled *ngIf="produits[id].quantite === 1"></i>
      <i class="far fa-minus-square ml-1" (click)="isMoins(produits[id])" *ngIf="produits[id].quantite > 1"></i>
      {{ produits[id].quantite }}
      <i class="far fa-plus-square" (click)="isPlus(produits[id])"></i>

      <br><br>
      <button class="btn btn-outline-primary w-50" type="submit">
        <i class="fas fa-shopping-cart"></i> Ajouter
      </button>

      </form>
    </div>
  </div>

</div>
